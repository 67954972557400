import React, { useEffect } from 'react';

export interface SnackbarProps {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  type: 'ERROR' | 'SUCCESS';
  className?: string;
  message?: string;
  duration?: number;
}

const Snackbar: React.FC<SnackbarProps> = ({
  isVisible,
  setIsVisible,
  className = '',
  message = '',
  duration = 5000,
  type,
}) => {
  useEffect(() => {
    if (!isVisible) return undefined;

    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, duration);

    return () => {
      clearTimeout(timeout);
    };
  }, [isVisible, setIsVisible, duration]);

  return (
    <section
      className={`fixed bottom-16 left-1/2 z-[100] flex w-full max-w-xs -translate-x-1/2 transform items-center justify-center space-x-4 rounded-lg border-2 p-4 text-neutral-800 transition-opacity duration-300 ease-in-out m:max-w-lg ${
        type === 'ERROR'
          ? 'border-red-dark bg-red-background'
          : 'border-green-dark bg-green-background'
      } ${
        isVisible
          ? 'z-10 opacity-100'
          : 'pointer-events-none z-0 hidden opacity-0'
      } ${className}`}
      role="alert"
    >
      <div className="pl-4 text-sm font-normal">{message}</div>
    </section>
  );
};

export default Snackbar;
