import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import { Input, InputVariants } from '@/components/atoms/Input/Input';
import {
  Switcher,
  SwitcherVariants,
} from '@/components/atoms/Switcher/Switcher';
import Accordion from '@/components/molecules/Accordion/Accordion';
import FormSelect from '@/components/molecules/FormSelect';
import { useVehicleFilterSettingsContext } from '@/context/VehicleFilterSettings';
import { useCalculator } from '@/hooks/useFinanceCalculator';
import {
  CALC_DEFAULT_MODE,
  CALC_FINANCE_LOAN_TERM_OPTIONS,
  CALC_LEASE_LOAN_TERM_OPTIONS,
  CALC_LEASE_MILEAGE_OPTIONS,
} from '@/lib/constants';
import clsx from 'clsx';
import { useState } from 'react';
import { EVSavingsSummaryAccordion } from '../EVSavingsSummaryAccordion/EVSavingsSummaryAccordion';
import {
  BreakdownItem,
  BreakdownSectionDivider,
  CalculatorWrapper,
  Total,
} from './Wrappers';
import { PaymentCalculatorProps } from './types';

export const PaymentCalculator = ({
  type,
  expanded = true,
  setExpanded,
  handleOfferButton,
  salePrice,
  setSalePrice,
  incentivesData,
  selectedIncentives,
  incentivesTotal,
  fuelSavings,
  onChangeMonthlyEstimatedPayment,
  onChangeTerm,
  handleSearch,
  openIncentiveModal,
  model,
  initialMode = CALC_DEFAULT_MODE,
}: PaymentCalculatorProps) => {
  const [mode, setMode] = useState<'Finance' | 'Lease'>(initialMode);

  const { settings: vehicleSettings } = useVehicleFilterSettingsContext();
  const {
    downPaymentType,
    downPayment,
    downPaymentPercent,
    invalidDownPayment,
    residualValueType,
    residualPercent,
    residualValue,
    tradeInValue,
    loanOrLeaseTerm,
    aprOrInterestRate,
    monthlyEstimatedPaymentNoEVSavings,
    monthlyEstimatedPayment,
    mileageFactorIndex,
    handleChangeMileageFactor,
    handleChangeMonthlyEstimatedPayment,
    handleChangeSalePrice,
    handleChangeDownPaymentType,
    handleChangeDownPayment,
    handleChangeTradeInValue,
    handleChangeTerm,
    handleChangeAprOrInterestRate,
    handleChangeResidualValue,
    handleChangeResidualValueType,
    handleChangeMode,
  } = useCalculator({
    mode,
    salePrice,
    setSalePrice,
    setMode,
    incentivesTotal,
    fuelSavings,
    onChangeMonthlyEstimatedPayment: () => {
      if (onChangeMonthlyEstimatedPayment) {
        if (vehicleSettings.evSavings) {
          onChangeMonthlyEstimatedPayment(monthlyEstimatedPayment);
        } else {
          onChangeMonthlyEstimatedPayment(monthlyEstimatedPaymentNoEVSavings);
        }
      }
    },
    onChangeTerm,
    displayFuelSavings: vehicleSettings.displayFuelSavings,
    model,
  });

  let inputs;

  const formattedSalePrice = salePrice.toLocaleString();

  const formattedDownPayment =
    downPaymentType === '$'
      ? Math.round(downPayment)
      : Math.round(downPaymentPercent);

  const formattedResidualValue =
    residualValueType === '$'
      ? Math.round(residualValue)
      : Math.round(residualPercent);

  if (mode === 'Finance') {
    inputs = (
      <section className="flex flex-grow flex-col gap-s">
        {setSalePrice ? (
          <Input
            type={InputVariants.Number}
            aria-label={type === 'homepage' ? 'Price' : 'Listed price'}
            value={salePrice}
            onChange={(val) => handleChangeSalePrice(val)}
            label={type === 'homepage' ? 'Price' : 'Listed price'}
            prepend="$"
            placeholder="0"
            step={1000}
          />
        ) : (
          <Input
            id="salePrice"
            label="Listed price"
            aria-label="Listed price"
            type={InputVariants.Filled}
            value={`$${formattedSalePrice}`}
          />
        )}

        <Input
          type={InputVariants.Number}
          aria-label="APR"
          value={aprOrInterestRate.toString()}
          onChange={(val) => handleChangeAprOrInterestRate(val)}
          label={'APR %'}
          append="%"
          placeholder="8.50"
          step={0.01}
        />
        <Input
          type={InputVariants.Number}
          aria-label="Down Payment"
          value={formattedDownPayment}
          onChange={(val) => handleChangeDownPayment(val)}
          invalid={invalidDownPayment}
          label="Down Payment"
          float={0}
          placeholder="0"
          suffixOption={{
            value: downPaymentType,
            options: [
              { label: '$', value: '$' },
              { label: '%', value: '%' },
            ],
            setSuffixOption: (val) =>
              handleChangeDownPaymentType(val as '%' | '$'),
          }}
        />
        {invalidDownPayment && (
          <div className="text-microMedium text-red-medium">
            Must be less than sale price
          </div>
        )}

        <Input
          aria-label="Trade-in value"
          type={InputVariants.Number}
          value={tradeInValue.toString()}
          onChange={(val) => handleChangeTradeInValue(val)}
          invalid={tradeInValue > salePrice}
          label="Trade-in value"
          prepend="$"
          placeholder="0"
          step={1000}
        />
        {tradeInValue > salePrice && (
          <div className="text-microMedium text-red-medium">
            Must be less than sale price
          </div>
        )}

        <div>
          <div className="label mb-xs block text-body2Medium text-neutral-800">
            Loan Term in months
          </div>
          <Switcher
            aria-label="Loan Term in months"
            variant={SwitcherVariants.Number}
            options={CALC_FINANCE_LOAN_TERM_OPTIONS.map((option) => ({
              text: option,
              value: option,
            }))}
            value={loanOrLeaseTerm}
            onChange={(e) => handleChangeTerm(e)}
          />
        </div>
      </section>
    );
  } else {
    // mode === 'Lease'
    inputs = (
      <div className="flex flex-grow flex-col gap-s">
        {setSalePrice ? (
          <Input
            type={InputVariants.Number}
            aria-label="Listed price"
            value={salePrice}
            onChange={(val) => handleChangeSalePrice(val)}
            label="Listed price"
            prepend="$"
            placeholder="0"
            step={1000}
          />
        ) : (
          <Input
            id="salePrice"
            label="Listed price"
            aria-label="Listed price"
            type={InputVariants.Filled}
            value={`$${formattedSalePrice}`}
          />
        )}
        <Input
          aria-label="APR %"
          type={InputVariants.Number}
          value={aprOrInterestRate.toString()}
          onChange={(val) => handleChangeAprOrInterestRate(val)}
          label="APR %"
          append="%"
          placeholder="0.00%"
          step={0.01}
        />
        <Input
          aria-label="Down Payment"
          type={InputVariants.Number}
          value={formattedDownPayment}
          onChange={(val) => handleChangeDownPayment(val)}
          invalid={invalidDownPayment}
          label="Down Payment"
          float={0}
          placeholder="0"
          suffixOption={{
            value: downPaymentType,
            options: [
              { label: '$', value: '$' },
              { label: '%', value: '%' },
            ],
            setSuffixOption: (val) =>
              handleChangeDownPaymentType(val as '$' | '%'),
          }}
        />
        {invalidDownPayment && (
          <div className="text-microMedium text-red-medium">
            Must be less than sale price
          </div>
        )}

        <FormSelect
          value={[CALC_LEASE_MILEAGE_OPTIONS[mileageFactorIndex].value]}
          label="Annual mileage"
          options={CALC_LEASE_MILEAGE_OPTIONS}
          onChange={(val) => {
            if (val.length === 0) return;
            handleChangeMileageFactor(
              CALC_LEASE_MILEAGE_OPTIONS.findIndex(
                (opt) => opt.value === val[1]
              )
            );
          }}
        />

        <div>
          <div className="label mb-xs block text-body2Medium text-neutral-800">
            Lease Term in months
          </div>
          <Switcher
            aria-label="Lease Term in months"
            variant={SwitcherVariants.Number}
            options={CALC_LEASE_LOAN_TERM_OPTIONS.map((option) => ({
              text: option,
              value: option,
            }))}
            value={loanOrLeaseTerm}
            onChange={(e) => handleChangeTerm(e)}
          />
        </div>

        <Input
          aria-label="Residual value of the vehicle"
          type={InputVariants.Number}
          value={formattedResidualValue}
          onChange={(val) => handleChangeResidualValue(val)}
          invalid={invalidDownPayment}
          label="Residual value"
          float={0}
          suffixOption={{
            value: residualValueType,
            options: [
              { label: '$', value: '$' },
              { label: '%', value: '%' },
            ],
            setSuffixOption: (val) =>
              handleChangeResidualValueType(val as '$' | '%'),
          }}
        />
        {invalidDownPayment && (
          <div className="text-microMedium text-red-medium">
            Must be less than sale price
          </div>
        )}
      </div>
    );
  }

  const inputsResponsiveWrapper = (
    <>
      <div
        className={clsx('flex', {
          'l:hidden': type === 'homepage',
          'ml:hidden': type === 'vdp',
        })}
      >
        <Accordion
          title={'Customize Payments'}
          buttonClassName="text-blue-medium"
          className="flex-grow"
          icon={{ id: 'chevron' }}
          expanded={expanded}
          setExpanded={setExpanded}
        >
          {inputs}
        </Accordion>
      </div>
      <div
        className={clsx('hidden', {
          'l:flex': type === 'homepage',
          'ml:flex': type === 'vdp',
        })}
      >
        {inputs}
      </div>
    </>
  );

  const headerPayment = vehicleSettings.evSavings
    ? monthlyEstimatedPayment
    : monthlyEstimatedPaymentNoEVSavings;

  const breakdown =
    mode === 'Finance' ? (
      <section className="flex flex-col gap-l">
        <section
          className={clsx('flex flex-col gap-s', {
            'bg-neutral-200 px-xl py-l l:bg-neutral-0 l:p-0':
              type === 'homepage',
          })}
        >
          <div className="flex flex-col gap-xs">
            <BreakdownItem
              label="Listed price"
              val={`$${salePrice.toLocaleString()}`}
            />
          </div>

          <BreakdownSectionDivider />

          <BreakdownItem
            label="Down Payment"
            val={`${downPayment > 0 ? '-' : ''}$${downPayment.toLocaleString()}`}
          />
          <BreakdownItem
            label="Trade-in value"
            val={`${tradeInValue > 0 ? '-' : ''}$${tradeInValue.toLocaleString()}`}
          />

          <BreakdownSectionDivider strong={true} />

          <Total
            label={'Total'}
            monthlyTotal={monthlyEstimatedPaymentNoEVSavings}
          />
        </section>
        {type !== 'homepage' && (
          <EVSavingsSummaryAccordion
            incentivesData={incentivesData}
            selectedIncentives={selectedIncentives}
            gasSavings={fuelSavings}
            openIncentiveModal={openIncentiveModal}
            incentivesTotal={incentivesTotal}
            variant="calculator"
          />
        )}
      </section>
    ) : (
      <section className="flex flex-col gap-l">
        <section className="flex flex-col gap-s">
          <BreakdownItem
            label="Down Payment"
            val={`${downPayment > 0 ? '-' : ''}$${downPayment.toLocaleString()}`}
          />

          <BreakdownSectionDivider strong />

          <Total
            label="Total"
            monthlyTotal={monthlyEstimatedPaymentNoEVSavings}
          />
        </section>
        <section className="flex flex-col">
          <EVSavingsSummaryAccordion
            incentivesData={incentivesData}
            selectedIncentives={selectedIncentives}
            gasSavings={fuelSavings}
            openIncentiveModal={openIncentiveModal}
            incentivesTotal={incentivesTotal}
            variant="calculator"
          />
        </section>
      </section>
    );

  return (
    <div
      className={clsx(
        'flex h-full w-full flex-col rounded-small border border-neutral-200',
        {
          'shadow-xl': type === 'homepage',
        }
      )}
    >
      <div className="flex h-full w-full">
        <CalculatorWrapper
          type={type}
          mode={mode}
          inputs={inputsResponsiveWrapper}
          breakdown={breakdown}
          monthlyEstimatedPayment={headerPayment}
          handleChangeMonthlyEstimatedPayment={
            handleChangeMonthlyEstimatedPayment
          }
          handleChangeMode={handleChangeMode}
          handleOfferButton={handleOfferButton}
        />
      </div>

      {type === 'homepage' && (
        <div className="h-content border-t-none flex w-full rounded-b-small bg-neutral-200 px-xl pb-xl pt-0 l:border-t l:border-neutral-300 l:bg-neutral-100 l:px-3xl l:py-xl">
          <Button
            aria-label="Search your budget"
            variant={ButtonVariants.Primary}
            onClick={() => {
              if (handleSearch) {
                handleSearch();
              } else {
                window.location.href = `/search/price_end/${Math.max(
                  0,
                  salePrice
                )}/sort/year_desc`;
              }
            }}
          >
            Search your budget
          </Button>
        </div>
      )}
    </div>
  );
};
